<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
export default {
  props: {
    oldTabelas: { type: Object },
    listGrupos2: { type: Array },
    /// parentSelected: { type: Object, required: true }
    ola: { type: Object },
  },
  components: {
     Multiselect
  },
  data() {
    return {
      loader: { get: false },
      titleBody: "Alterar Grupo",
      tabelas: {
        ttp_nome: null,
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      rota: {
        nome: null,
      },
      manutencao: {
        data:null,
        veiculo_id:null,
        km_atual:null,
        servico_id:null,
        realizada:null,
        valor:null,
        obs:null,
        tp_regra: null,
        dp_roda: null,
        passarEm: null,
        tp_dia_mes_ano: null,
      },
      show_input_km: false,
      show_input_tempo: false,
      show_input_km_tempo: false,
      select_tipo: null,
      select_d_m_a: null,
      select_servico:null,
      dias: [
        {
          id: 1,
          nome: "dia",
        },
        {
          id: 2,
          nome: "mês",
        },
        {
          id: 3,
          nome: "ano",
        },
      ],
      Servicos: [
        {
          id: 1,
          nome: "nome",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Kilometragem",
        },
        {
          id: 2,
          nome: "tempo",
        },
        {
          id: 3,
          nome: "Km ou tempo",
        },
      ],
    };
  },
  validations: {
    tabelas: {
      ttp_nome: { required },
    },
  },
  created() {
    this.rota = this.oldTabelas;
    this.manutencao = this.oldTabelas
  },
  mounted() {
    this.setNewTitle();
    //console.log(this.manutencao)
   // this.insert_embarque = this.ola;
   this.inicializar_multiselects();
   
  },
  methods: {

    inicializar_multiselects(){
      let veiculo_selecionado = this.veiculos.find((v) => v.id == this.manutencao.veiculo_id )
      console.log(veiculo_selecionado)
      let servico_selecionado = this.Servicos.find((s) => s.id == this.manutencao.servico_id )
      console.log(servico_selecionado)
      this.select_veiculos = veiculo_selecionado
      this.select_servico = servico_selecionado
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields().then(() => {
        this.setPut();
      });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      this.coverter_float()
      console.log("Gravou");
      console.log(this.manutencao);
      this.$emit("doPut", this.manutencao);
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
    doPut() {
      this.$emit("doPut", this.manutencao);
    },
    selecionar_dia_mes_ano(e) {
      this.manutencao.tp_dia_mes_ano = e.id;
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.manutencao.dp_roda.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.manutencao.dp_roda = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_km_atual() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.manutencao.km_atual.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.manutencao.km_atual = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor2() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.manutencao.valor.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.manutencao.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    verifica_tipos(e) {
      console.log(e);
      this.manutencao.tp_regra = e.id;
      if (e.id == 1) {
        this.show_input_km = true;
        this.show_input_tempo = false;
        this.show_input_km_tempo = false;
      } else if (e.id == 2) {
        this.show_input_km = false;
        this.show_input_tempo = true;
        this.show_input_km_tempo = false;
      } else if (e.id == 3) {
        this.show_input_km_tempo = true;
        this.show_input_tempo = false;
        this.show_input_km = false;
      }
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.manutencao.dp_roda == "string") {
        this.manutencao2 = this.manutencao;
        let stringValor = this.manutencao2.dp_roda;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.manutencao.dp_roda = valorFlutuante;
      }
      if (typeof this.manutencao.valor == "string") {
        this.manutencao3 = this.manutencao;
        let stringValor = this.manutencao3.valor;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.manutencao.valor = valorFlutuante;
      }
      if (typeof this.manutencao.km_atual == "string") {
        this.manutencao4 = this.manutencao;
        let stringValor = this.manutencao3.km_atual;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.manutencao.km_atual = valorFlutuante;
      }
    },
    seleciona_servico(e){
      this.manutencao.servico_id = e.id
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <Label>Data de Regisrto</Label>
          <input
            v-model="manutencao.data"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "*Veículo" }}</Label>
          <multiselect
            v-model="select_veiculos"
            label="nome"
            @select="seleciona_veiculo($event)"
            :options="veiculos"
          ></multiselect>
        </div>
        <div class="col-md-3">
          <Label>Km atual do Veículo</Label>
          <input
            v-model="manutencao.km_atual"
            placeholder="digite"
            @input="formatInput_km_atual()"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>Serviço</Label>
          <multiselect
            v-model="select_servico"
            label="nome"
            @select="seleciona_servico($event)"
            :options="Servicos"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <Label class="mr-4">Essa Mutenção já foi realizada ?</Label>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <input
            v-model="manutencao.realizada"
            type="radio"
            value="1"
            class="estilo_input"
          />
          <label for="" class="mr-4 ml-2">não</label>
          <input
            v-model="manutencao.realizada"
            type="radio"
            value="2"
            class="estilo_input"
          />
          <label for="" class="ml-2">sim</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1 mt-4 mb-4" role="tab">
            <b-button block v-b-toggle.pedido variant="light"
              >Regras de Manutenção</b-button
            >
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            <div class="card mt-4">
              <div class="card-body cor">
                <div
                  class="row d-flex justify-content-center align-items-center"
                >
                  <div class="col-md-4 text-center">
                    <Label>Tipo:</Label>
                    <multiselect
                      v-model="select_tipo"
                      :options="tipos"
                      @select="verifica_tipos($event)"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                  <div v-if="show_input_km" class="col-md-4">
                    <Label>Depois que Rodar:</Label>
                    <input
                      v-model="manutencao.dp_roda"
                      @input="formatInput_valor()"
                      type="text"
                      class="form-control text-left"
                    />
                  </div>
                  <div v-if="show_input_tempo" class="col-md-4">
                    <Label>quando psssar(em): </Label>
                    <input
                      v-model="manutencao.passarEm"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div v-if="show_input_tempo" class="col-md-4">
                    <Label>&nbsp; </Label>
                    <multiselect
                      v-model="select_d_m_a"
                      :options="dias"
                      @select="selecionar_dia_mes_ano($event)"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                  <div v-if="show_input_km_tempo" class="col-md-2">
                    <Label>Depois que rodar</Label>
                    <input
                      v-model="manutencao.dp_roda"
                      @input="formatInput_valor()"
                      type="text"
                      class="form-control text-left"
                    />
                  </div>
                  <div v-if="show_input_km_tempo" class="col-md-1">
                    <Label class="mt-4"> Ou km </Label>
                  </div>
                  <div v-if="show_input_km_tempo" class="col-md-2">
                    <Label>Quando Passar(em) </Label>
                    <input
                      v-model="manutencao.passarEm"
                      type="number"
                      class="form-control text-left"
                    />
                  </div>
                  <div v-if="show_input_km_tempo" class="col-md-2">
                    <Label>&nbsp; </Label>
                    <multiselect
                      v-model="select_d_m_a"
                      @select="selecionar_dia_mes_ano($event)"
                      :options="dias"
                      label="nome"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="row mb-4 d-flex justify-content-center align-items-center">
        <div class="col-md-6 text-center">
          <Label>Valor</Label>
          <input
            v-model="manutencao.valor"
            placeholder="digite"
            @input="formatInput_valor2()"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-6 text-center">
          <Label>Observações:</Label>
          <textarea
            v-model="manutencao.obs"
            placeholder="digite"
            name=""
            id=""
            cols="30"
            rows="1"
            class="form-control"
          ></textarea>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPut()">Atualizar</button>
        </div>
      </div>
    </div>
  </div>
</template>